import { create } from 'apisauce';

export const api = create({
    baseURL: 'https://alummadata.farookcircle.com'
});

// dev

// export const api = create({
//   baseURL: 'http://192.168.43.30:5000',
// });
